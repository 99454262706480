.validatePaymentContainer{
  display: flex;
  justify-content: center;
}
.headerValidateTitle{
  padding: 20px 0px;
  font-size: 16px;
}

.title {
  margin-bottom: 10px;
  margin-top: 0px;
}

.fuel {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  margin-left: 10px;
  margin-right: 10px;
}

.confirmation {
  margin-top: 10px;
  text-align: center;
  width: 700px;
}

.confirmContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  
}

.tableContainer{
  display: flex;
  align-items: center;
  flex-direction: column;
  .table {
    margin-top: 30px;
    width: 400px;
   
    .row {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      text-align: left;
      margin-bottom: 18px;
      label:first-child {
        font-size: 14px;
        font-weight: normal;
        color: var(--color-cement);
        width: 40%;
      }
      label:last-child {
        font-size: 14px;
        font-weight: 500;
        color: var(--color-night);
        width: 60%;
      }
    }
  }
}
