.intro {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  margin: 0;
  margin-bottom: 3px;
}
