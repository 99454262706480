.options {
  max-height: 200px;
  overflow-y: auto;
  margin-top: 4px;
  box-shadow: 0.3px 0.3px 0.3px 2px rgb(0 0 0 / 5%);
  border-radius: 4px;
  display: none;
  position: absolute;
  z-index: 3;
  width: 300px;

  &.active {
    display: block !important;
  }

  .option {
    padding: 10px 20px;
    color: var(--color-night);
    display: flex;
    align-items: center;
    background-color: #fff;
    &:hover {
      background: var(--color-baby-blue) !important;
      color: var(--color-blue);
      font-weight: 500;
    }
    &.selected {
      background: var(--color-whitesmoke);
    }
  }
}

.circle {
  width: 15px !important;
  height: 15px !important;

  margin-right: 10px;
}

.empty {
  display: none;
}

.textfield {
  &.active {
    :global {
      .MuiInputBase-input:focus::placeholder {
        color: var(--color-night) !important;
        opacity: 1;
      }
    }
  }
}

.clickable {
  cursor: pointer;
}
