.container {
  height: calc(100vh - 60px);
  width: 100%;
  margin: -27px 0px -27px 0px;
  padding: 27px 0px 0px 0px;
}

.gridContainer {
  margin-top: 20px;
  height: calc(100vh - 150px);
}

.items {
  height: calc(100vh - 207px);
  // background: red;
  overflow-y: auto;
  overflow-x: hidden;
  border: solid 1px #ebeef7;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  .item {
    transition: background 1s;
    &:not(:last-child) {
      border-bottom: solid 1px #ebeef7;
    }
    &:hover {
      background: #f5f6fa;
    }
  }
}
.content {
  height: calc(100vh - 150px);
  overflow-y: auto;
}

.content::-webkit-scrollbar,
.items::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.content::-webkit-scrollbar-thumb,
.items::-webkit-scrollbar-thumb {
  background-color: #00000047;
  border-radius: 25px;
}
