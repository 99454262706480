.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 11111111111111;
  padding-left: 200px;
}

.centerCircle {
  padding-left: 0;
}

.circleContainer {
  outline: 0;
}

.circle {
  color: #ffffff;
}
