.container {
  height: 100vh;
  width: 100%;
  position: fixed;
  overflow-y: auto;
}

.title {
  margin-top: 0px;
  line-height: 26px;
}

.optionContainer {
  display: flex;
  justify-content: space-between;
}

.remember,
.forgotPassword {
  display: flex;
  align-items: center;
  margin: 5px 0px 10px 0px;
}

.forgotPassword {
  span {
    font-weight: bold;
    color: var(--color-sky);
    cursor: pointer;
  }
}

.header {
  height: 70px;
  padding: 14px 28px;
  .logoImage {
    height: 39px;
    width: 160px;
    object-fit: contain;
  }
}

.content {
  height: calc(100% - 120px);
  min-height: 500px;
  display: flex;
  align-items: center;
  overflow-x: auto;
}

.contentContainer {
  max-width: 80%;
  display: flex;
  min-width: 1366px;

  .image {
    text-align: center;
    align-items: center;
    width: 45%;
  }
  .contentImage {
    width: 80%;
  }
  .login {
    width: 35%;
    margin-top: 3%;
  }
}

.loginButton {
  width: 100%;
}
