.filter {
  display: flex;
  align-items: center;
}

.date {
  // width: 30%;
  // width: 80%;
}

.fieldContent {
  width: 250px;
}


.dateRange {
  // margin-left: 10px;
  width: 250px;
  margin: auto;
  margin-right: 0px;
}

.productCode {
  // width: 15%;
  margin: 20px -1.2rem;
}

.search {
  width: 700px;
  margin-left: 10px;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-right: 10px;
}

.diesel {
  background-color: #e2b40d;
}

.gas91 {
  background-color: #10ac84;
}

.gas95 {
  background-color: #ee3b34;
}

.gas97 {
  background-color: #2e4491;
}

.downloadButton {
  justify-content: flex-end;
  display: flex;
}