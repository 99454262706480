.filters {
  margin-top: 37px;
}

.table {
  margin-top: 30px;
}

.subBusinessId {
  margin-top: 5px;
  font-size: 11px;
  width: 120px;
  color: #95a2aa;
  line-height: 1.5;
}

.iconDisabled {
  color: #95a2aa;
  cursor: not-allowed;
}
