.container {
  display: flex;
  align-items: center;
  text-align: center;
  padding-left: 200px;
  overflow-x: auto !important;
}

.header {
  padding: 20px 24px 20px 24px;
  display: flex;
  justify-content: space-between;
  .title {
    margin: 0px;
  }
}

.content {
  min-width: 450px;
  max-width: 1200px;
  height: auto;
  background: var(--color-white);
  margin: auto;
  outline: 0;
  border-radius: 10px;
  padding-bottom: 30px;
}

.close {
  background: var(--color-baby-blue);
  color: var(--color-night);
  width: 24px;
  height: 24px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  margin-top: -10px;
  margin-right: -15px;
  .icon {
    font-size: 14px;
    font-weight: bold;
    margin: auto;
    transition: font-size 0.1s;
    color: var(--color-night);
  }
  &:hover {
    .icon {
      font-size: 14.5px;
    }
  }
}

.modalContent {
  padding: 0px 20px 0px 20px;
  text-align: left;
}

.removePadding {
  padding-left: 0px !important;
}
