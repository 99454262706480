@import url("../images/style.css");

.MuiAutocomplete-option:hover {
  background: var(--color-baby-blue);
  color: var(--color-blue);
  font-weight: 500;
}
.MuiListItem-root.MuiMenuItem-root {
  font-size: 14px;
}

.MuiListItem-root.MuiMenuItem-root:hover {
  background: var(--color-baby-blue);
  color: var(--color-blue);
  font-weight: 500;
}

* {
  font-family: Roboto;
}

.MuiCircularProgress-root {
  color: var(--color-blue);
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.text-center {
  text-align: center;
}

.full-width {
  width: 100%;
}

.text-right {
  text-align: right;
}

.gray {
  color: var(--color-cement);
}

.italic {
  font-style: italic;
}

.min-100 {
  min-width: 100px;
}

.min-80 {
  min-width: 80px;
}

.min-70 {
  min-width: 70px;
}

.min-60 {
  min-width: 60px;
}

.min-50 {
  min-width: 50px;
}

.nowrap {
  word-break: keep-all;
  white-space: nowrap;
}

.link {
  color: var(--color-mid-blue);
  text-decoration: underline;
}

.MuiPaper-elevation8 {
  box-shadow: 1px 2px 2px -3px rgb(0 0 0 / 2%), 0px 2px 11px 1px rgb(0 0 0 / 2%),
    0px 2px 20px 2px rgb(0 0 0 / 4%) !important;
}
