@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?2noijl');
  src:  url('fonts/icomoon.eot?2noijl#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?2noijl') format('truetype'),
    url('fonts/icomoon.woff?2noijl') format('woff'),
    url('fonts/icomoon.svg?2noijl#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-clock:before {
  content: "\e912";
}
.icon-fleet-activity:before {
  content: "\e900";
}
.icon-fuel:before {
  content: "\e901";
}
.icon-cash-in:before {
  content: "\e902";
}
.icon-dashboard:before {
  content: "\e903";
}
.icon-pricing:before {
  content: "\e904";
}
.icon-station:before {
  content: "\e905";
}
.icon-filter:before {
  content: "\e906";
}
.icon-download:before {
  content: "\e907";
}
.icon-grid:before {
  content: "\e908";
}
.icon-add-user:before {
  content: "\e909";
}
.icon-gas:before {
  content: "\e90a";
}
.icon-payment:before {
  content: "\e90b";
}
.icon-icon-mall:before {
  content: "\e90c";
}
.icon-icon-gear:before {
  content: "\e90d";
}
.icon-checkmark:before {
  content: "\e911";
}
.icon-outline-trending_up-24px:before {
  content: "\e90e";
}
.icon-uniE90F:before {
  content: "\e90f";
}
.icon-uniE910:before {
  content: "\e910";
}
.icon-uniE912:before {
  content: "\e912";
}
.icon-checkmark1:before {
  content: "\e913";
}
.icon-uniE914:before {
  content: "\e914";
}
.icon-uniE915:before {
  content: "\e915";
}
.icon-uniE916:before {
  content: "\e916";
}
.icon-uniE917:before {
  content: "\e917";
}
.icon-uniE918:before {
  content: "\e918";
}
.icon-uniE919:before {
  content: "\e919";
}
.icon-uniE91A:before {
  content: "\e91a";
}
.icon-uniE91B:before {
  content: "\e91b";
}
.icon-uniE91C:before {
  content: "\e91c";
}
.icon-uniE91D:before {
  content: "\e91d";
}
.icon-uniE91E:before {
  content: "\e91e";
}
.icon-uniE91F:before {
  content: "\e91f";
}
.icon-back:before {
  content: "\ea40";
}