.backlink {
  display: inline-block;
  margin-bottom: 30px;
  cursor: pointer;
}

.backlinkContent {
  display: flex;
  align-items: center;
  display: row;
}

.icon {
  color: var(--color-blue);
}

.text {
  font-weight: 500;
  color: var(--color-blue);
  text-decoration: underline;
}
