.paymentSuccessContainer{
  display: flex;
  justify-content: center;
}
.title {
  margin-bottom: 10px;
  margin-top: 0px;
}
.headerPaymentSuccessTitle{
  padding: 20px 0px;
  font-size: 16px;
}
.fuel {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  margin-left: 10px;
  margin-right: 10px;
}

.confirmation {
  margin-top: 10px;
  text-align: center;
}

.table {
  margin-top: 30px;
  width: 400px;
  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
    margin-bottom: 18px;
    label:first-child {
      font-size: 14px;
      font-weight: normal;
      color: var(--color-cement);
      width: 100px;
      min-width: 100px;
      margin-right: 32px;
    }
    label:last-child {
      font-size: 14px;
      font-weight: 500;
      color: var(--color-night);
    }
  }
}

.titleContainer {
  background-color: var(--color-gray);
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  .titleContent {
    text-align: center;
  }
  .successIcon {
    width: 79.3px;
    margin-right: 12px;
    margin-left: 12px;
  }
}

.fieldContainer > p {
  width: 25%;
  color: var(--color-purple);
}
.fieldContainer > div {
  width: 30%;
  p {
    width: 250px;
  }
}
.fieldContainer {
  text-align: left;

  .formContainer {
    width: 250px;
  }
}

.doneButton {
  padding: 5px 20px;
  height: 36px;
  min-width: 110px;
  span {
    font-weight: 700;
    font-size: 14px;
  }
}
