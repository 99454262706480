.container {
  background: linear-gradient(to right, #1c54a1 -9%, #3a8dde);

  height: 50px;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
}

.text {
  color: var(--color-white);
  margin: auto;
}
