.button {
  background: var(--color-mid-blue);
  transition: background 0.5s;
  height: 38px;

  .text {
    color: white;
    font-weight: 500;
    align-items: center;
    display: flex;
  }

  .icon {
    font-size: 15px;
    margin-right: 10px;
  }

  &:hover,
  &.active {
    background: var(--color-deep-blue);
    .text {
      color: #ffffff;

      > .count {
        color: var(--color-mid-blue);
        background: var(--color-soft-blue);
      }
    }
    .icon {
      font-size: 15px;
      margin-right: 10px;
      color: #ffffff;
    }
  }
}

.title {
  margin-bottom: 10px;
}

.filterContent {
  position: absolute;
  margin-top: 10px;
  width: 450px;
  background: #ffffff;
  padding: 20px;
  box-shadow: 0 5px 5px 5px rgb(0 0 0 / 2%);
  border-radius: 5px;
  border: 1px solid #ebeef7;
  z-index: 2;

  &.inActive {
    display: none;
  }
}

.action {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.overlay {
  opacity: 0.2;
  position: absolute;
  top: 0px;
  height: 100%;
  left: 0px;
  width: 100%;
  z-index: 1;
}

.count {
  border-radius: 20px;
  margin-left: 6px;
  background: var(--color-soft-blue);
  padding: 0px 9px;
  transition: background 0.5s;
  color: var(--color-mid-blue);
}

.filterIcon {
  min-width: 36px;
  display: flex;
  align-items: center;
  span {
    font-size: 24px !important;
    color: #ffffff;
  }
}

.filterIconImage {
  width: 20px;
  height: 20px;
  fill: #ffffff;
}
