.title {
  text-align: center;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 500px;
  margin: auto;
  background-color: var(--color-whitesmoke);
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.04);
  overflow: hidden;
}

.station {
  padding: 20px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:not(:first-child) {
    border-top: 1px solid rgba(0, 0, 0, 0.04);
  }
}

.icon {
  transition: margin-right 0.3s;
}
.station:hover {
  background-color: var(--color-ash);
  .icon {
    margin-right: -10px;
  }
}
