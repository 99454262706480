.text {
  margin: 0;
  color: var(--color-night);
}

.label {
  color: var(--color-cement);
  margin-bottom: 5px;
}

.subtitle {
  color: var(--color-cement);
  font-size: 14px;
}

.strong {
  font-weight: 500;
}

.strike {
  text-decoration: line-through;
}

.italic {
  font-style: italic;
}
