.title {
  font-size: 20px !important;
  margin-top: 20px;
  margin-bottom: 20px;
}

.subtitle {
  font-size: 16px !important;
  color: var(--color-night);
}

.fuel {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  margin-left: 10px;
  margin-right: 10px;
}

.failed {
  text-align: center;
}

.successIcon {
  width: 149px;
}

.text {
  font-size: 14px;
}

.gotItButton {
  margin-top: 20px;
  padding: 5px 20px;
  height: 36px;
  min-width: 110px;
  span {
    font-weight: 700;
    font-size: 14px;
  }
}
