.intro{
  border-bottom: 1px solid #ebeef7;
}
.headerLoader{
  text-align: center;
}
.container {
  width: 400px;
  margin-top: 30px;
  margin-bottom: 30px;
  .textfield {
    width: 200px;
  }
}

.buttons {
  height: 36px;
}

.table {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  .row {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    text-align: left;
    margin-bottom: 18px;
    label:first-child {
      font-size: 14px;
      font-weight: normal;
      color: var(--color-cement);
      width: 30%;
    }
    label:last-child {
      font-size: 14px;
      font-weight: 500;
      color: var(--color-night);
      width: 70%;
    }
  }
}

.subtitle {
  font-size: 16px !important;
  font-weight: bold;
  margin-bottom: 0px;
  margin-top: 0px;
  color: var(--color-night);
}

.detailsContainer {
  display: flex;
  flex-direction: row;
  .contentPayment {
    padding-top: 30px;
    flex: 1;
  }
  .contentDetails {
    flex: 1;
    height: 100vh;
    // margin-top: -27px;
    margin-right: -36px;
    margin-bottom: -27px;
    padding: 32px;
    border-left: 1px solid #ebeef7;
  }
  .labelFuelCode {
    font-weight: 500;
  }
  .productTypeContainer {
    display: flex;
    padding: 16px 30px 16px 20px;
    border-radius: 4px;
    border: solid 1px #e4e5ec;
    flex-direction: row;
  }
  .productTypeDetails {
    display: flex;
    flex-direction: column;
    .textTitle {
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 10px;
    }
    .textBalance {
      font-size: 14px;
      margin-bottom: 6px;
      color: var(--color-cement);
    }
  }
}

.popOver {
  display: inline-block;
  .stationListLink {
    text-decoration: underline;
    color: var(--color-blue);
    font-weight: 500;
    cursor: pointer;
  }
}

.boldRed {
  color: var(--color-deep-red) !important;
  font-weight: bold;
}


.titlePayment {
  margin-bottom: 10px;
  margin-top: 0px;
}

.fuelPayment {
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconPayment {
  margin-left: 10px;
  margin-right: 10px;
}

.confirmationPayment {
  text-align: center;
}
.tableContainer{
  display: flex;
  align-items: center;
  flex-direction: column;
  .tablePayment {
    margin-top: 30px;
    .rowPayment {
      display: flex;
      flex-direction: row;
      align-items: center;
      text-align: left;
      margin-bottom: 18px;
      label:first-child {
        font-size: 14px;
        font-weight: normal;
        color: var(--color-cement);
        width: 100px;
        min-width: 100px;
        margin-right: 32px;
      }
      label:last-child {
        font-size: 14px;
        font-weight: 500;
        color: var(--color-night);
      }
    }
  }
  
}

.titleContainer {
  background-color: var(--color-gray);
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  .titleContent {
    text-align: center;
  }
  .successIcon {
    width: 79.3px;
    margin-right: 12px;
    margin-left: 12px;
  }
}

.fieldContainer > p {
  width: 25%;
  color: var(--color-purple);
}
.fieldContainer > div {
  width: 35%;
}
.fieldContainer {
  text-align: left;

  .formContainer {
    width: 250px;
  }
}

.doneButton {
  padding: 5px 20px;
  height: 36px;
  min-width: 110px;
  span {
    font-weight: 700;
    font-size: 14px;
  }
}
