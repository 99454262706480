.horizontal {
  display: flex;
  align-items: center;
}

.container,
.filter {
  width: 100%;
  margin: 20px 0px;
}

.message {
  margin-top: 5px !important;
  font-size: 12px;
  color: var(--color-cement) !important;
}

.error {
  color: var(--color-salsa) !important;
}

.labelLeft {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.labelTop {
  display: flex;
  // justify-content: space-between;
  align-items: flex-start;
  // margin-top: 10px;
}
.labelTopText {
  margin-top: 10px;
}

.title {
  display: block;
}
