.overflow {
  word-wrap: break-word;
  max-width: 150px;
}

.remarks {
  max-height: 160px;
  overflow-x: auto;
}

.table {
  table {
    width: 2000px;
  }
}

.iconDisabled {
  color: #95a2aa;
  cursor: not-allowed;
}

.subBusinessId {
  margin-top: 5px;
  font-size: 11px;
  width: 120px;
  color: #95a2aa;
  line-height: 1.5;
}
