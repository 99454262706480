:root {
  --color-purple: #753bbd;
  --color-deep-blue: #2e4491;
  --color-mid-blue: #0054a7;
  --color-soft-blue: #babfdf;
  --color-sky: #1373d1;
  --color-indigo: #44498d;
  --color-deep-red: #de2f28;
  --color-salsa: #ee3b34;
  --color-grass: #019f3e;
  --color-emerald: #10ac84;
  --color-cheddar: #ffaa01;
  --color-gold: #e2b40d;
  --color-cement: #a1aeb7;
  --color-concrete: #f6f6f6;
  --color-whitesmoke: #f5f5f5;
  --color-ash: #e2e7ee;
  --color-night: #34384b;
  --color-blush: #fdecea;
  --color-white: #ffffff;
  --color-blue: #1373d1;
  --color-gray: #fafafa;
  --color-light-blue: #dbf4ff;
  --color-cyan: #02b2b8;
}
