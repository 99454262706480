.item {
  font-family: "Roboto";
  font-size: 14px;
  padding: 12px 20px;
  &:hover {
    font-weight: 500;
    color: var(--color-purple);
    background: var(--color-baby-blue);
  }
}

.container {
  width: 200px !important;
  padding: 0px;
  :global {
    .MuiPaper-root {
      width: 200px !important;
      min-width: 200px !important;
    }
  }
}

.arrow {
  background: #ffffff !important;
  border-right: 1px solid #e4e5ec !important;
  border-top: 1px solid #e4e5ec !important;
}
.content {
  border: 1px solid #e4e5ec !important;
  padding: 0px !important;
  cursor: pointer;
}

.background {
  background: #ffffff !important;
}

.icon {
  color: var(--color-night);
}
